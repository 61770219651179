<template>
  <div id="game-demo">
    <section class="section">
      <div
        class="columns game-columns is-1-mobile is-3-desktop is-desktop is-mobile is-multiline is-flex"
      >
        <div class="column is-12">
          <h3 class="title is-3">{{ data.name }}</h3>
        </div>

        <div class="column is-12">
          <img :src="data.picture" alt="" style="width: 100%" />
        </div>

        <div class="column is-12">
          <a :href="data.demo_url" target="_blank"
            ><button class="button is-link">Mainkan Sekarang</button></a
          >
        </div>

        <div class="column is-12">
          <div v-html="data.narrative"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import GameDemoServices from "@/services/game_demo_services";

export default {
  name: "Home",
  components: {},
  setup() {
    const route = useRoute();
    const data = ref({
      id: undefined,
      name: undefined,
      picture: undefined,
      demo_url: undefined,
      narrative: undefined,
      provider_id: undefined,
      provider: {
        id: undefined,
        provider: undefined,
        icon: undefined,
      },
      createdAt: new Date(),
    });

    onMounted(async () => {
      fetchDataFromServer();
    });

    const fetchDataFromServer = async () => {
      let response = await GameDemoServices.getOne(route.params.id);
      data.value = response.data.data;
    };

    const redirectTo = (id) => {
      router.push({ name: "GameDemoView", params: { id } });
    };

    watch(
      () => route.params.name,
      () => {
        fetchDataFromServer();
      }
    );

    return {
      route,
      data,
      redirectTo,
    };
  },
};
</script>

<style>
.game-demo-card {
  border-radius: 1em;
}
</style>
